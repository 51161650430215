/*
This file is for the display and organizing of elements
*/
* {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.App {
    width: 100vw;
    height: 100vh;
    background-color: lightpink;
    display: flex;
    flex-direction: column;
  }

  .bg {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 0;

      .img {
        position: fixed;
        width: 100%;
        height: 100%; 
      }
      .wave1 {
          position: absolute;
          bottom: -275px;
          z-index: 3;
      }
      .wave2 {
        position: absolute;
        bottom: -150px;
        z-index: 2;
    }
    .sun {
        position: absolute;
        z-index: 1;
        width: 50%;
        left: 25%;
        top: -5%;
    }
  }
  
  .App-header {
    justify-self: flex-start;
    background-color: #282c34;
    // height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;

        h4 {
            margin : 0 5%;
            justify-self: flex-start;
            }
        
        nav {
            // border: 1px solid green;
            width: 40%;
            justify-self: center;
            margin : 0 6%;
            
            ul {
                display: flex;
                flex-direction: row;
                align-items: center; 
                justify-content: space-evenly;
                padding: 0;

                li {
                    list-style-type: none;
                    // border: 1px solid greenyellow;
                    Link {
                      color: white;  
                    }
                    
                }
            }
        }

  }
    
main {
    background-color: whitesmoke;
    height: 90vh;
    z-index: 20;
}

footer {
    justify-self: flex-end;
    background-color: #282c34;
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    justify-content: space-evenly;
    z-index: 20;
    p {
        margin: 0 2%;
        // border: 1px solid rebeccapurple;
        font-size: 2.5rem;
       
    }
    a {
        margin: 0 3%;
        // border: 1px solid rebeccapurple;
        font-size: 1.8rem;
    }
  }

  h3 {
      font-size: 1.5rem;
      margin: 0;
  }

  //====================================================================================================================================================
  // Presentation component
  //====================================================================================================================================================

.presentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;

    h1 {
        font-size: 8rem;
        margin: 2rem 0;
        // border: 1px solid green;
        z-index: 20;
    }

    .cards-ctn {
        display: flex;
        flex-direction: row;
        // border: 1px solid black;
        width: 90%;
        z-index: 20;
        justify-content: space-evenly;
    }

    .card {
        border: 1px solid black;
        margin: 1rem;
        padding: 10px 15px;
        width: 20%;
        height: 32rem;
        z-index: 20;

        h3 {
            margin : 5px 0;
        }

        ul {
           list-style-type: none; 
           margin : 0;
           padding: 0;

           li {
               font-weight: bold;
               margin-top: 10px;
               ul {
                   margin-left: 10px;
                   
                   li {
                     font-weight: normal;  
                     margin-top: 0px;
                   }
               }
           }
        }
        p {
            margin : 2px 0;
            font-style: italic;
            font-size: .9rem;
            font-weight: normal;
        }
        
    }
}

  //====================================================================================================================================================
  // Skills component
  //====================================================================================================================================================

  .skills {

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;

    h1 {
        font-size: 5rem;
    }

    .cards-ctn {
        
        z-index: 10;
        display: flex;
        flex-direction: row;
        width: 90%;
        z-index: 20;
        justify-content: space-evenly;

        .skills-card-ctn {
            border: 1px solid black;
            margin: 1rem;
            padding: 15px;
            width: 20%;
            height: 30rem;
            z-index: 20;

            .skills-grid-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-auto-rows: 100px;
                gap: 15px;
                margin-top: 2rem;
                z-index: 20;
        
                .skill-card {
                width: 100px;
                height: 100px;
                z-index: 20;
              }
              .skill-card p {
                text-align: center;
                font-size: 1rem;
                font-weight: bold;
                line-height: initial;
                width: 100%;
                z-index: 20;
                margin-bottom: 0;
              }
              .skill-card i {
                font-size: 2.5rem;
                z-index: 20;
              }
              .one-line i{
                margin-bottom: 1rem;
                z-index: 20;
              }
              }


        }






    }

    
    

    
  }

    //====================================================================================================================================================
  // Projects component
  //====================================================================================================================================================

  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // border: 1px solid aqua;
    height: 100%;
    z-index: 20;

    h1{
        margin: .5rem;
        z-index: 20;
        font-size: 5rem;
        
    }

    .projects-container  {
       
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        // border: 1px solid green;
        width: 68%;
        z-index: 20;

        // Carousel --------------------------------------------------------------

        .carousel {
            overflow: hidden;
            // border: 1px solid red;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 88%;
            z-index: 20;

            .inner {
                white-space: nowrap;
                transition: transform 0.3s ;
                // border: 1px solid blue;
                display: flex;
                flex-direction: row;
                z-index: 20;
            }
    
            .project-card {
                display: inline-flex;
                flex-direction: column;
                width: 22rem;
                margin: 1rem;
                border: 1px solid aqua;
                padding: 15px;
                height: 35rem;
                white-space: normal;
                z-index: 20;
                align-items: center;

                img {
                    width: 100%;
                    height: 150px;
                    z-index: 20;
                }
                .project-card-img-p-ctn {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-items: flex-start;
                    align-items: flex-start;
                    height: 65%;
                  }
                  h3 {
                   
                    align-self: center; 
                  }
                  h4 {
                      margin: 1%;
                  }

                .link-ctn {
                   justify-self: flex-end;
                   display: flex;
                    flex-flow: row nowrap;
                    margin: 0;
                    width: 100%;
                    justify-content: space-between;
                    font-size: 1.2rem;
 
                }
            }
    
        }

        button {

            i {
                font-size: 6rem;
                z-index: 20;
            }
        }
      
    }
    

    
    
  }

  