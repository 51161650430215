/*
This file is for the color scheme and purely aesthetic elements
*/
@font-face {
  font-family: "VCR OSD Mono";
  src: url("../Ressources/VCR_OSD_MONO_1.001.ttf");
}
.App {
  background: rgb(131, 58, 180);
  background: linear-gradient(180deg, rgb(11, 0, 40) 0%, rgb(37, 9, 84) 24%, rgb(93, 16, 122) 53%, rgb(184, 37, 115) 84%, rgb(255, 138, 117) 100%);
}

.App-header {
  background: linear-gradient(180deg, rgb(25, 0, 40) 0%, rgba(25, 0, 40, 0.2) 100%);
  background-color: transparent;
}
.App-header h4 {
  font-family: "VCR OSD Mono", sans-serif;
  font-weight: 900;
  color: white;
  text-shadow: 2px 1px 0px #ff0b72, -2px -1px 0px #0b9bff;
  font-size: 3rem;
  letter-spacing: -3px;
  transform: skew(-10deg);
}
.App-header nav ul li a {
  text-decoration: none;
  padding: 7px;
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 2px 1px 0px #ff0b72, -2px -1px 0px #0b9bff;
}
.App-header nav ul li a:hover {
  text-shadow: 6px 3px 4px #ff0b72, -6px -3px 4px #0b9bff;
}
.App-header nav ul li a:active {
  text-shadow: 6px 3px 4px #0b9bff, -6px -3px 4px #ff0b72;
}

main {
  background-color: transparent;
}

footer {
  background: linear-gradient(0deg, rgb(25, 0, 40) 0%, rgba(25, 0, 40, 0.2) 100%);
}
footer p {
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 2px 1px 0px #ff0b72, -2px -1px 0px #0b9bff;
}
footer a {
  text-decoration: none;
  padding: 7px;
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 2px 1px 0px #ff0b72, -2px -1px 0px #0b9bff;
  border-radius: 0;
}
footer a:hover {
  text-shadow: 6px 3px 4px #ff0b72, -6px -3px 4px #0b9bff;
}
footer a:active {
  text-shadow: 6px 3px 4px #0b9bff, -6px -3px 4px #ff0b72;
}

h3 {
  color: white;
  text-shadow: 1px 0px 0px #ff0b72, -1px 0px 0px #0b9bff;
}

h1 {
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 5px 3px 0px #ff0b72, -5px -3px 0px #0b9bff;
  background-color: rgb(25, 0, 40);
  width: 70%;
  text-align: center;
  transform: skew(-10deg);
}

.presentation .card {
  border-radius: 10px;
  background: repeating-linear-gradient(180deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px), repeating-linear-gradient(90deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px);
  background-color: rgba(25, 0, 40, 0.9);
  color: #ff7686;
  border-color: #ff7686;
  box-shadow: 0 0 5px 2px rgba(255, 11, 114, 0.5);
}
.presentation .card ul {
  color: #ff7f6a;
}
.presentation .card ul a {
  color: #ff7f6a;
  text-decoration: underline 1px dashed #ff7f6a;
}
.presentation .card ul a:hover {
  color: #fefab3;
  text-decoration: underline 1px solid #fefab3;
}
.presentation .card ul li ul li {
  color: #ff7686;
}
.presentation .card #link {
  display: inline-block;
  margin: 1rem 0;
  text-decoration: none;
  color: #ff0b72;
  border: 1px solid #ff0b72;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px transparent;
  text-shadow: 0px 0px 5px transparent;
  background-color: transparent;
  padding: 5px;
}
.presentation .card #link:hover {
  color: #ff0b72;
  border: 1px solid #ff0b72;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #ff0b72;
  text-shadow: 0px 0px 5px #ff0b72;
  background-color: rgba(255, 11, 113, 0.1882352941);
}
.presentation .card #link:active {
  color: #fefab3;
  border: 1px solid #fefab3;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #fefab3;
  text-shadow: 0px 0px 5px #fefab3;
  background-color: rgba(254, 250, 179, 0.1882352941);
}
.presentation .card p {
  color: #ff7686;
}
.presentation strong {
  color: #ff7f6a;
}

.skills h1 {
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 5px 3px 0px #ff0b72, -5px -3px 0px #0b9bff;
  background-color: rgb(25, 0, 40);
  width: 70%;
  text-align: center;
  transform: skew(-10deg);
}
.skills p {
  z-index: 20;
}
.skills h3 {
  margin: 5px 0;
  color: white;
  text-shadow: 1px 0px 0px #ff0b72, -1px 0px 0px #0b9bff;
}
.skills .skills-card-ctn {
  border-radius: 10px;
  background: repeating-linear-gradient(180deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px), repeating-linear-gradient(90deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px);
  background-color: rgba(25, 0, 40, 0.9);
  color: #ff7686;
  border-color: #ff7686;
  box-shadow: 0 0 5px 2px rgba(255, 11, 114, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skills .skills-grid-container .skill-card {
  background-color: transparent;
}
.skills .skills-grid-container .skill-card p {
  color: #ff7686;
}
.skills .skills-grid-container .skill-card i {
  color: #ff7686;
}

.projects h1 {
  font-family: "VCR OSD Mono", sans-serif;
  color: white;
  text-shadow: 5px 3px 0px #ff0b72, -5px -3px 0px #0b9bff;
  background-color: rgb(25, 0, 40);
  width: 70%;
  text-align: center;
  transform: skew(-10deg);
}
.projects .projects-container .carousel .project-card {
  border-radius: 10px;
  background: repeating-linear-gradient(180deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px), repeating-linear-gradient(90deg, transparent, transparent 15px, rgb(25, 0, 40) 15px, rgb(25, 0, 40) 16px, transparent 16px);
  background-color: rgba(25, 0, 40, 0.9);
  color: #ff7686;
  border-color: #ff7686;
  box-shadow: 0 0 5px 2px rgba(255, 11, 114, 0.5);
}
.projects .projects-container img {
  box-shadow: 0 0 5px 2px rgba(255, 11, 114, 0.3137254902);
  margin-bottom: 1rem;
}
.projects .projects-container a {
  text-decoration: none;
  padding: 5px;
  color: #ff0b72;
  border: 1px solid #ff0b72;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px transparent;
  text-shadow: 0px 0px 5px transparent;
  background-color: transparent;
}
.projects .projects-container a:hover {
  color: #ff0b72;
  border: 1px solid #ff0b72;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #ff0b72;
  text-shadow: 0px 0px 5px #ff0b72;
  background-color: rgba(255, 11, 113, 0.1882352941);
}
.projects .projects-container a:active {
  color: #fefab3;
  border: 1px solid #fefab3;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #fefab3;
  text-shadow: 0px 0px 5px #fefab3;
  background-color: rgba(254, 250, 179, 0.1882352941);
}
.projects .projects-container button {
  color: #ff7686;
  border: 1px solid #ff7686;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px transparent;
  text-shadow: 0px 0px 5px transparent;
  background-color: transparent;
}
.projects .projects-container button:hover {
  color: #ff7686;
  border: 1px solid #ff7686;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #ff7686;
  text-shadow: 0px 0px 5px #ff7686;
  background-color: rgba(255, 118, 134, 0.1882352941);
}
.projects .projects-container button:active {
  color: #fefab3;
  border: 1px solid #fefab3;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px #fefab3;
  text-shadow: 0px 0px 5px #fefab3;
  background-color: rgba(254, 250, 179, 0.1882352941);
}
.projects .projects-container .inactive {
  color: #555;
  border: 1px solid #555;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px transparent;
  text-shadow: 0px 0px 5px transparent;
  background-color: transparent;
  padding: 5px;
  margin: 0;
}

